import React from 'react'
import Layout from "../components/layout"
import Categories from "../components/categories"
import { ProjectsDirectoryHeader, ProjectsTable } from '../components/projects'
import SEO from "../components/seo"

const IndexPage = ({ data }) => {

  const tableData = data.allStrapiResource.edges.map(x => {
    return {
      id: x.node.id,
      name: x.node.name,
      grant: x.node.grant,
      github_link: x.node.github_link,
      website_link: x.node.website_link,
      subsocial_link: x.node.subsocial_link,
      email: x.node.email,
      short_description: x.node.short_description,
      logo: x.node.logo
    }
  })

  const categories = data.allStrapiResourceCategory.edges.map(x => {
    return {
      id: x.node.id,
      name: x.node.name,
      slug: x.node.slug
    }
  })

  return (
    <Layout>
      <SEO title="Resources" />
      <div className="mgt-medium column">
        <div className="column">
          <ProjectsDirectoryHeader Name="Resources Directory" IsProjects={false} />
          <Categories Categories={categories} />
        </div>
        <ProjectsTable data={tableData} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`  
  query {
    allStrapiResource {
      edges {
        node {
          id
          name
          grant
          github_link
          website_link
          subsocial_link
          email
          short_description  
          logo {
            childImageSharp {
              fixed(width: 128, height: 128) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allStrapiResourceCategory{
      edges{
        node{
          id
          name
          slug
        }
      }
    }
  }
`